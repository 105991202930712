/* Datatables filters */
.yadcf-filter-reset-button {
    //margin-top: -1px;
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
    margin-left: 5px;
    line-height: 26px;
}

.yadcf-filter-range, .yadcf-filter-range-number,
.yadcf-filter-date, .yadcf-filter-range-date {
    /*border: 1px solid #ccc;*/
    border: 1px solid #E5E6E7;
    vertical-align: middle;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    /*border-radius: 3px;
    font-weight: bold;*/
    position: relative;
}
.yadcf-filter-range-number {
    width: 60px !important;
}
.yadcf-filter-date, .yadcf-filter-range-date {
    width: 80px !important;
}

.yadcf-filter-range-date-seperator, .yadcf-filter-range-number-seperator,
.yadcf-filter-range, .yadcf-filter-range-number {
    margin-left: 2px !important;
    margin-right: 2px !important;;
}

.yadcf-filter-wrapper > input {
    @include input-placeholder {
        color: lighten($text-color, 30%) !important;
    }
}

.input-filter {
    width: 130px !important;
    /*font-weight: bold;*/
    font-size: 12px;
    height: 30px;
}

.yadcf-filter-wrapper-inner {
    border: 0 !important;
}

.inuse, .ui-slider-range .inuse, .yadcf-filter-range-number-slider .inuse {
    background: #A2E0DD;
}

.dataTables_wrapper {
    padding-bottom: 10px;
}

div.dataTables_wrapper div.dataTables_processing {
    color: #ffffff;
    font-weight: bolder;
    background: #004b75;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    z-index: 9999;
}

div.dataTables_wrapper div.dataTables_info {

    padding-top: 4px;
}

/* yadcf-filter-wrapper */
.yadcf-filter-wrapper .select2-selection__arrow b {
    margin-top: -4px !important;
}

.yadcf-filter-wrapper .select2-container .select2-search--inline .select2-search__field {
    font-size: 12px;
    //padding: 1px;
    /*font-weight: bold;*/
    width: 100% !important;
    @include input-placeholder {
        color: lighten($text-color, 30%) !important;
    }
}

.yadcf-filter-wrapper .select2-container .select2-selection--single .select2-selection__placeholder {
    font-size: 12px;
    font-weight: normal;
    color: lighten($text-color, 30%) !important;
    //padding: 1px;
}

.yadcf-filter-wrapper .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 12px;
    padding: .375rem .75rem !important;
}

.yadcf-filter-wrapper .select2-container--default.select2-container--focus .select2-selection--multiple {
    /*border: 1px solid #00aca4;*/
    border: 1px solid #1c84c6;
}

.yadcf-filter-wrapper .select2-container--default .select2-selection--multiple {
    border: 1px solid #e5e6e7;
    border-radius: 0;
}

.yadcf-filter-wrapper .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 5px;
}

.yadcf-filter-wrapper .select2-container .select2-selection--single {
    min-height: 30px;
    margin-top: -1px;
}

.yadcf-filter-wrapper .select2-container .select2-selection--multiple {
    min-height: 30px;
    margin-top: -1px;
}

.yadcf-filter-wrapper .select2-container .select2-selection__clear {
    display: none;
}

.yadcf-filter-wrapper .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection--multiple {
    border: 1px solid #e5e6e7;
}

.yadcf-filter-range-date::-webkit-input-placeholder,
.yadcf-filter-range-number::-webkit-input-placeholder {
    color: #999999;
}
/* WebKit, Blink, Edge */
.yadcf-filter-range-date:-moz-placeholder,
.yadcf-filter-range-number:-moz-placeholder {
    color: #999999;
}
/* Mozilla Firefox 4 to 18 */
.yadcf-filter-range-date::-moz-placeholder,
.yadcf-filter-range-number::-moz-placeholder {
    color: #999999;
}
/* Mozilla Firefox 19+ */
.yadcf-filter-range-date:-ms-input-placeholder,
.yadcf-filter-range-number:-ms-input-placeholder {
    color: #999999;
}
/* Internet Explorer 10-11 */
.yadcf-filter-range-date:-ms-input-placeholder,
.yadcf-filter-range-number:-ms-input-placeholder {
    color: #999999;
}

/* Microsoft Edge */
.yadcf-filter-wrapper .select2-results__option {
    padding: 4px;
    font-size: 12px;
}

.yadcf-filter-wrapper {
    display: inline-flex !important;
    position: relative !important;
}

/* DATATABLES */
table.dataTable {
    border-collapse: collapse !important;
    width: 100% !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    @include media-breakpoint-down(md) {
        justify-content: center !important;
    }
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc_disabled:after, table.dataTable thead .sorting_desc_disabled:before {
    opacity: 0.6;
}

.dataTable th:last-child {
    white-space: nowrap;
}