.dropdown-menu {
    font-size: 13px;
    right: 10px !important;
    .dropdown-item{
        padding: 0.50rem 0.7rem;
        white-space: normal;
        &.active{
            background: none !important;
        }
    }
}

.dropdown-item:active{
    background: none !important;
}

.dropdown-item.active, .dropdown-item:active{
    color: inherit !important;
}

.pull-right > .dropdown-menu {
    right: 0 !important;
}