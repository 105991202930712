.c-form {
    /*.form-control {
        //padding: 0.2rem 0.75rem;
        @include fontSize(14px);
        @include media-breakpoint-down(lg){
            @include fontSize(resize(14px, 0.9));
        }
        !*@include media-breakpoint-down(md){
            @include fontSize(resize(14px, 0.8));
        }*!
    }*/
    input, textarea{
        @include input-placeholder {
            color: lighten($text-color, 40%) !important;
        }
    }
}