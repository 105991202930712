.c-footer{
    background: $main-color;
    padding: 20px 30px;
    color: #fff;

    &__logo{
        max-height: 130px;
    }

    a{
        @include color-a(#fff);
    }

    ul{
        list-style: none;
        li{
            margin-top: 5px;
        }
    }
}