.bootstrap-select .dropdown-toggle {
    background-color: #ffffff;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    padding: 6px 12px;
    color: #676a6c;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #d1d0d1;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #d1d0d1;
    background-color: #ffffff;
    border: 1px solid #e5e6e7;
}

.bootstrap-select .dropdown-menu .dropdown-item {
    padding: 0.10rem 0.7rem;
    white-space: normal;
}

.bootstrap-select .dropdown-menu li .selected {
    background-color: #ddd;
}

.is-invalid .bootstrap-select .dropdown-toggle {
    border: 1px solid #dc3545 !important;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #5897fb !important;
}