.c-breadcrumbs{
    &__list{
        background: #eee;
        border-radius: 0;
        padding: 0.5rem 1rem;
        margin: 0;
    }
    &__item{
        font-weight: bold;
        @include fontSize(13px);
        @include media-breakpoint-down(sm){
            @include fontSize(12px);
        }
    }
    &__link{
        @include color-a($text-color);
    }
    &__link:hover{
        color: #293846;
    }
    &__active{
        color: $main-color;
    }
}
