@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}

@mixin color-a($color) {
    color: $color;

    &:hover,
    &:active,
    &:focus {
        color: $color;
    }
}

//Styles for placeholders
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin cover-background {
    background-size: cover;
    background-position: center center;
}