
// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
//@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

//Settings
@import "settings/settings.variables";

// Inspinia theme
@import "theme/theme";

// Datatables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";

//Datetime Picker
@import "~pc-bootstrap4-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";

// Select2
@import "~select2/src/scss/core";

// Selectpicker
@import "~bootstrap-select/sass/bootstrap-select";

// Sweet Alert 2
@import "~sweetalert2/src/sweetalert2";

//Toastr
@import "~toastr/toastr";

//Tools
@import "tools/tools.mixins";

//Components
@import "components/components.breadcrumbs";
@import "components/components.form";
@import "components/components.footer";
@import "components/components.menu-top";

//Objects
@import "objects/objects.titles.scss";

//Trumps
@import "trumps/trumps.alerts";
@import "trumps/trumps.datatables";
@import "trumps/trumps.dropdowns";
@import "trumps/trumps.datepicker";
@import "trumps/trumps.filestyle";
@import "trumps/trumps.select2";
@import "trumps/trumps.selectpicker";
@import "trumps/trumps.upload-files";
@import "trumps/trumps.nav-pills";
@import "trumps/trumps.utilities";
@import "trumps/trumps.tables";
@import "trumps/trumps.swal";
@import "trumps/trumps.toggle";

.user {
  @include fa-icon;
  @extend .fas;

  &:before {
    content: fa-content($fa-var-user);
  }
}

.twitter {
  @include fa-icon;
  @extend .fab;

  &:before {
    content: fa-content($fa-var-twitter);
  }
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

td.large-column {
    word-break: break-all;
}
