.c-menu-top{
    &__logo{
        height: 85px;
        @include media-breakpoint-down(md){
            height: 70px;
        }
        @include media-breakpoint-down(sm){
            height: 50px;
        }
    }

    a{
        @include color-a(#fff);
    }

    /* change the background color */
    .navbar-custom{
        background-color: #fff;
    }
    /* change the brand and text color */
    .navbar-custom .navbar-brand,
    .navbar-custom .navbar-text {
        color: rgba(255,255,255,.8);
    }
    /* change the link color */
    .navbar-custom .navbar-nav .nav-link {
        color: $secondary-color;
        font-size: 18px;
        padding-right: 15px;
        padding-left: 15px;
        @include media-breakpoint-down(md){
            font-size: resize(18px);
        }
    }
    /* change the color of active or hovered links */
    .navbar-custom .nav-item.active .nav-link,
    .navbar-custom .nav-item:focus .nav-link,
    .navbar-custom .nav-item:hover .nav-link {
        color: $main-color;
    }

    .navbar-custom .navbar-toggler{
        color: #fff !important;
        border-color: #fff;
        .navbar-toggler-icon{
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }

    .dropdown-menu{
        background: $secondary-color;
        @include color-a(#fff);
        font-size: 15px;
        min-width: 600px;
        margin-left: -200px;
        @include media-breakpoint-down(md){
            font-size: resize(15px);
            margin-left: 25px;
            background: none;
            box-shadow: none;
        }

        .dropdown-item{
            color: #fff;
            padding: 0.8rem 1.5rem;

            &:hover, &:focus{
                background: $main-color;
            }
        }
    }
}
