.minimalize-styl-2 {
  margin: 12px;
}
.nav-header {
  padding: 7px;
}
.nav > li > a {
  padding: 10px 20px 10px 25px;
  font-size: 14px;
}
.nav > li.active {
  border-left: 4px solid $main-color;
}
.dropdown-divider {
  margin: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #DDDDDD;
}
.ibox {
  border-top: 2px solid $main-color;
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ibox-content{
  padding: 15px;
}
.ibox-title {
  border-width: 0;
  padding: 10px 88px 10px 15px;
  min-height: 0;
}
.ibox-title h4 {
  color: $main-color;
}
.ibox-tools {
  top: 10px;
}
.wrapper-content {
  padding: 14px 10px 10px;
}
.table {
  margin-bottom: 0;
}
.table-detail tr>td:first-child {
  font-weight: 700;
  width: 25%;
}

a {
  text-decoration: none;
  outline: none !important;
  color: $main-color;

  &:hover {
    color: $secondary-color;
  }

  &:visited, &:hover, &:focus, &:active {
    text-decoration: none;
  }
}
ul {
  margin-bottom: 0;
}
label{
  margin: 0 !important;
}
.alert {
  padding: 0.5rem;
}
.register-box {
  width: 100%;
  z-index: 100;
  margin: 0 auto;
  padding: 20px 200px 20px 200px;
  background-color: rgba(255, 255, 255, 0.8);
  @include media-breakpoint-down(lg){
    padding: 20px;
  }
}

.user-box {
  max-width: 400px;
  z-index: 100;
  padding: 40px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
}
.form-check-label.is-invalid {
  color: #dc3545;
}
.form-text-value {
  font-size: 0.9rem;
}
.card-body {
  padding: 0.75rem;
}

.navbar-divider {
  border-top: 1px solid darken($nav-text-color, 25%);
  height: 0;
  margin: 0.45rem 0;
  overflow: hidden;
}
.navbar-divider + .navbar-divider {
  display: none !important;
}
