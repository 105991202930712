.file-selector-area {
    border: 1px dashed #ced4da;
    border-radius: 5px;
    background: #fff3db;
    padding: 7px 7px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    @include fontSize(14px);
    color: $text-color;
    @include media-breakpoint-down(sm){
        @include fontSize(resize(14px, 0.6));
    }
}
.file-selector-area.dragover {
    background: #f6f6f6;
}
.progress-bar{
    display: none;
    margin-top: 10px;
    margin-bottom: 10px;
}