.select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #495057 !important;
    line-height: 1.5 !important;
    font-size: 0.9rem;
}

.select2-container--default .select2-selection--single{
    border: 1px solid #e5e6e7 !important;
    height: auto !important;
    border-radius: 0 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered{
    padding: .475rem .75rem !important;
}

#select2-yadcf-filter--list-table-2-container{
    padding: .350rem .75rem !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 100% !important;
}

select.form-control:not([size]):not([multiple]){
    height: auto;
}

.is-invalid .select2-selection--single {
    border: 1px solid #dc3545 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.04);
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.02);
}