.nav-pills{
    margin-bottom: 20px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: $main-color;
}

.nav-pills >li >a {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    color: #000000;
}

.nav-pills >li >a:hover {
    background-color: #fff3db;
}